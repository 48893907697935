.hero-text-contact {
    font-size: 50px;
    margin-bottom: 30px; 
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
}

@media (max-height: 900px) {
    .hero-text-contact {
        font-size: 20px;
        text-align: center;
    }

    .sub-text-contact {
        font-size: 18px !important;
    }

    textarea {
        min-height: 100px !important;
    }

    form {
        padding: 30px 0 30px 0px !important;
    }

}

.sub-text-contact {
    font-size: 25px; 
    font-family: 'Roboto', sans-serif;
}

#submitButton {
    font-size: 1rem;
}

.terms-text {
    color: var(--accent);
    font-weight: bold;
  }
  