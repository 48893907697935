.link-item {
    padding: 10px 15px;
    display: flex;
    align-content: center;
    transition: 0.3s;
  }
    
.link-item:hover {
  background-color: #f46c6c;
  color:white;
}


@media (max-width: 500px) {
  .logo-wrapper {
    margin-left: 0px !important;
  }
}

