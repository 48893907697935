@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

#root {
  width: 100%;
}

body {
  font-family: "Roboto", serif;
  font-weight: 400;
  background: var(--background);
  color: var(--text);
  --text: #141414;
  --secondarytext: #1d1717;
  --background: #f2f2f2;
  --primary: #f8ae3f;
  --footer: #371937;
  --secondary: #371937;
  --accent: #f46c6c;
  --text-50: #fff5e6;
  --text-100: #ffebcc;
  --text-200: #fed69a;
  --text-300: #fec267;
  --text-400: #fead34;
  --text-500: #fe9901;
  --text-600: #cb7a01;
  --text-700: #985c01;
  --text-800: #653d01;
  --text-900: #331f00;
  --text-950: #190f00;

  --background-50: #f7edf7;
  --background-100: #efdcef;
  --background-200: #dfb9df;
  --background-300: #d095d0;
  --background-400: #c072c0;
  --background-500: #b04fb0;
  --background-600: #8d3f8d;
  --background-700: #6a2f6a;
  --background-800: #462046;
  --background-900: #231023;
  --background-950: #120812;

  --primary-20: hsl(36, 93%, 61%, 20%);
  --primary-50: #fef5e6;
  --primary-100: #fdeace;
  --primary-200: #fbd59d;
  --primary-300: #fac16b;
  --primary-400: #f8ac3a;
  --primary-500: #f69709;
  --primary-600: #c57907;
  --primary-700: #945b05;
  --primary-800: #623c04;
  --primary-900: #311e02;
  --primary-950: #190f01;

  --secondary-50: #f7edf7;
  --secondary-100: #efdcef;
  --secondary-200: #dfb9df;
  --secondary-300: #d095d0;
  --secondary-400: #c072c0;
  --secondary-500: #b04fb0;
  --secondary-600: #8d3f8d;
  --secondary-700: #6a2f6a;
  --secondary-800: #462046;
  --secondary-900: #231023;
  --secondary-950: #120812;

  --accent-50: #fde7e7;
  --accent-100: #fbd0d0;
  --accent-200: #f8a0a0;
  --accent-300: #f47171;
  --accent-400: #f14141;
  --accent-500: #ed1212;
  --accent-600: #be0e0e;
  --accent-700: #8e0b0b;
  --accent-800: #5f0707;
  --accent-900: #2f0404;
  --accent-950: #180202;


}

.slick-slide {
  height: 400px;
}

.slick-slide > div {
  height: 100%;
}

h1, h2, h3, h4, h5 {
  font-family: "Roboto", serif;
  font-weight: 700;
}

html {font-size: 100%;} /* 16px */

h1 {font-size: 5.652rem; /* 90.4px */}

h2 {font-size: 3.997rem; /* 64px */}

h3 {font-size: 2.827rem; /* 45.28px */}

h4 {font-size: 1.999rem; /* 32px */}

h5 {font-size: 1.414rem; /* 22.56px */}

small {font-size: 0.707rem; /* 11.36px */}
 html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none!important;
    color: white;
  }
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #fff inset; /* Change the color to your own background color */
    box-shadow: transparent;
    -webkit-text-fill-color: #fff;
  }

  .container {
    width: 100%;
    padding: 0px 30px;
  }
  .regular {
    font-weight: 400!important;
  }
  .semiBold {
    font-weight: 600!important;
  }
  .extraBold {
    font-weight: 800!important;
  }

  /* HELLPERS */
  .textCenter {
    text-align: center
  }
  .animate {
    transition: 0.3s ease;
  }
  .pointer {
    cursor: pointer;
  }
  .relative {
    position: relative;
  }
  .textCenter {
    text-align: center;
  }
  .textLeft {
    text-align: left;
  }
  .textRight {
    text-align: right;
  }
  .x100 {
    width: 100%;
  }
  .active-nav {
    background-color: var(--accent);
  }

  .active-sidebar {
    border-bottom: 2px solid #371937;
  }

  .tag {
    padding: 5px 10px;
    margin: 10px 0;
  }
  .shadow {
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
  }
  /* FLEXBOX */
  .flex {
    display: flex;
    justify-content:center;
  }
  .flexSpaceCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexNullCenter {
    display: flex;
    align-items: center;
  }
  .flexColumn {
    flex-direction: column;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexSpaceNull {
    display: flex;
    justify-content: space-between;
  }

  /* FONT SIZES */
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.75rem;
  }
  .font13 {
    font-size: 0.813rem;
  }
  .font14 {
    font-size: 0.875rem;
  }
  .font15 {
    font-size: 0.938rem;
  }
  .font18 {
    font-size: 1.125rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
  .font25 {
    font-size: 1.563rem;
  }
  .font30 {
    font-size: 1.875rem;
  }
  .font40 {
    font-size: 2.5rem;
  }
  .font60 {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
  @media only screen and (max-width: 859px) {
    .font11 {
      font-size: 0.688rem;
    }
    .font12 {
      font-size: 0.688rem;
    }
    .font13 {
      font-size: 0.75rem;
    }
    .font14 {
      font-size: 0.813rem;
    }
    .font15 {
      font-size: 0.813rem;
    }
    .font18 {
      font-size: 0.938rem;
    }
    .font20 {
      font-size: 1.125rem;
    }
    .font25 {
      font-size: 1.25rem;
    }
    .font30 {
      font-size: 1.5rem;
    }
    .font40 {
      font-size: 1.875rem;
    }
    .font60 {
      font-size: 2.8rem;
    }
  }
  /* BORDER RADIUS */
  .radius8 {
    border-radius: 1rem;
  }
  .radius6 {
    border-radius: 0.6rem;
  }
  /* COLORS */
  .darkColor {
    color: #0B093B;
  }
  .purpleColor {
    color: #7620FF;
  }
  .orangeColor {
    color: #F2B300;
  }
  .lightColor {
    color: #F5F5F5;
  }
  .coralColor {
    color: #C4FFFF;
  }
  .greenColor {
    color: #49CB86;
  }
  .greyColor {
    color: #707070;
  }
  .whiteColor {
    color: #fff;
  }